import Vue, { Component, VueConstructor } from 'vue'

import './styles/main.scss'
import { Content } from '@simpl/core/types/graphql'
import { TrackingVisualizationDataAdjustments } from '@simpl/tracking-evaluation/types'
import { TickAmount } from '@simpl/cms/types'

export interface CMSComponentProperty {
  type: string
  key: string
  component?: Component
  values?: any
  order?: number
  [key: string]: any
}

export interface CMSComponentPropertyGroup {
  key: string,
  icon?: string,
  order?: number,
  properties?: CMSComponentProperty[]
}

type CMSComponentDefinition = {
  preview?: string | (() => string)
  category?: {
    key: string
    categoryIndex?: number
    order?: number
  },
  hideInLibrary?: boolean,
  hideDeleteButton?: boolean,
  containerClass?: string[],
  dragDrop?: {
    allowedParents?: string[],
    deniedParents?: string[]
  },
  draggable?: boolean,
  stackingOrder?: {
    index?: number
  },
  props? (): CMSComponentPropertyGroup[],
  templateChildren?: ({
    tag: string
    properties?: object
  })[],
  layout?: {
    disabled: string[]
  },
  tracking?: {
    getTestData?: (content?: Content) => any[],
    parseTrackingForVisualization: (tracking: Record<string, any>, content?: Content, adjustments?: TrackingVisualizationDataAdjustments) => any[],
    parseTrackingForTable?: (tracking: Record<string, any>, content?: Content, categories?: string[] | number[]) => any[]
  },
  visualization?: {
    diagramTypes?: string[],
    hasNumericCategories?: boolean,
    distributable?: boolean,
    getTextForCategories?: (content: Content) => number[] | string[],
    getTextForLegend?: (content: Content) => number[] | string[],
    getTickAmount?: (content: Content) => TickAmount
  }
}

export default async function (_Vue: VueConstructor) {
  _Vue.config.optionMergeStrategies.cms = function (
    parent?: CMSComponentDefinition,
    child?: CMSComponentDefinition
  ) {
    const result = {
      ...parent,
      ...child
    } as CMSComponentDefinition

    const parentProps = parent?.props || (() => ([]))
    const childProps = child?.props || (() => ([]))

    result.props = function mergedCMSProps () {
      const parentPropResult = parentProps.call(this)
      const childPropResult = childProps.call(this)

      const mergedProps: CMSComponentPropertyGroup[] = [...parentPropResult]
      for (const childPropGroup of childPropResult) {
        let parentGroup = mergedProps.find(g => g.key === childPropGroup.key)
        if (!parentGroup) {
          mergedProps.push({
            key: childPropGroup.key,
            icon: childPropGroup.icon,
            order: childPropGroup.order,
            properties: []
          })

          parentGroup = mergedProps.find(g => g.key === childPropGroup.key)
        } else {
          if (childPropGroup.icon) parentGroup.icon = childPropGroup.icon
          if (childPropGroup.order) parentGroup.order = childPropGroup.order
        }

        parentGroup!.properties = [
          ...(parentGroup!.properties || []),
          ...(childPropGroup.properties || [])
        ]

        parentGroup!.properties.sort(sortByOrder)
      }

      mergedProps.sort(sortByOrder)

      return mergedProps
    }

    result.category = result.category || { key: 'general' }

    return result
  }

  const components = (await import('./components'))

  for (const name in components) {
    if (!Vue.component(name)) {
      Vue.component(name, (components as any)[name])
    }
  }
}

const sortByOrder = (a: any, b: any) => {
  const aOrder = a.order || 0
  const bOrder = b.order || 0

  return aOrder > bOrder
    ? 1
    : bOrder > aOrder
      ? -1
      : 0
}

declare module 'vue/types/options' {
  export interface ComponentOptions<V extends Vue> {
    cms?: CMSComponentDefinition
  }
}
